<template>
  <main class="view view--task">
    <section class="header">
      <div class="header__left"></div>
      <div class="header__center">
        <p class="title">{{ $helper.ucfirst(date.format("dddd")) }}</p>
        <p class="subtitle">{{ date.format("DD/MM/YYYY") }}</p>
      </div>
      <div class="header__right">
        <button v-on:click="$router.go(-1)" class="btn btn-close"></button>
      </div>
    </section>
    <div v-if="not_today" class="alert alert-warning">
      {{ $t("You are viewing a task that is not planned for today.") }}
    </div>
    <section v-if="!editable" class="alert alert-warning">
      {{
        $t(
          "You cannot change the task anymore because it is already accepted or your internet connection is down."
        )
      }}
    </section>
    <section class="accordion">
      <article class="accordion-item accordion-item--active">
        <button class="accordion-item__header">
          <div class="img"><img src="img/icon-pin.png" alt="" /></div>
          <div class="title">{{ $t("Projectinfo") }}</div>
          <span v-if="slot.task.description && showTaskDescription" class="badge">!</span>
        </button>
        <div class="accordion-item__body">
          <table>
            <tr>
              <td>{{ $t("Employer") }}:</td>
              <td>
                <strong>{{ userName }}</strong>
              </td>
            </tr>
            <tr v-if="slot.persons && Object.keys(slot.persons).length > 0">
              <td>{{ $t("Together with") }}:</td>
              <td>
                <div v-for="person in slot.persons" :key="person.id">
                  <strong
                    >{{ person.first_name }} {{ person.last_name }}</strong
                  >
                </div>
              </td>
            </tr>
            <tr v-if="client.name">
              <td>{{ $t("Client") }}:</td>
              <td>
                <strong>{{ client.name }}</strong
                ><br />
                <p v-if="client.phone" style="margin: 5px 0 5px 0">
                  <a :href="'tel:' + client.phone">{{ client.phone }}</a
                  ><br />
                </p>
                <p v-if="client.email" style="margin: 0 0 5px 0">
                  <a :href="'mailto:' + client.email">{{ client.email }}</a>
                </p>
              </td>
            </tr>
            <tr>
              <td>{{ $t("Task") }}:</td>
              <td>
                <strong>{{ slot.task.title }}</strong>
              </td>
            </tr>
            <tr v-if="slot.task.location">
              <td>{{ $t("Location") }}</td>
              <td class="small">
                {{ slot.task.location.title }}<br />
                {{ slot.task.location.street }}
                {{ slot.task.location.number }}<br />
                {{ slot.task.location.zip }}
                {{ slot.task.location.city }}, {{ slot.task.location.country
                }}<br />
                <div v-if="slot.task.location.description">
                  ({{ slot.task.location.description }})
                </div>
              </td>
            </tr>
            <tr v-if="slot.task.description && showTaskDescription" class="small">
              <td></td>
              <td>
                <vue-markdown
                  :anchorAttributes="{ target: '_blank' }"
                  :source="slot.task.description"
                ></vue-markdown>
              </td>
            </tr>
            <tr v-if="slot.task.relatable_type == 'project'">
              <td>{{ $t("Project") }}:</td>
              <td>
                <strong>{{ slot.task.relatable.title }}</strong>
                <small
                  v-if="slot.task.relatable.internal_id"
                  style="margin-left: 5px"
                  >{{ slot.task.relatable.internal_id }}</small
                >
              </td>
            </tr>
            <tr v-if="
                slot.task.relatable_type === 'project' &&
                !slot.task.location &&
                slot.task.relatable &&
                slot.task.relatable.locations[0]
            ">
              <td>{{ $t("Project location") }}:</td>
              <td class="small">
                {{ slot.task.relatable.locations[0].title }}<br />
                {{ slot.task.relatable.locations[0].street }}
                {{ slot.task.relatable.locations[0].number }}<br />
                {{ slot.task.relatable.locations[0].zip }}
                {{ slot.task.relatable.locations[0].city }}, {{ slot.task.relatable.locations[0].country
                }}
              </td>
            </tr>
            <tr
              v-if="
                slot.task.relatable_type == 'project' &&
                slot.task.relatable.checkinatwork_workplacenumber
              "
            >
              <td>Checkinatwork:</td>
              <td>
                {{ slot.task.relatable.checkinatwork_workplacenumber }}
              </td>
            </tr>
            <tr
              v-if="slot.task.relatable && slot.task.relatable.description && showProjectDescription"
              class="small"
            >
              <td></td>
              <td>
                <vue-markdown
                  :anchorAttributes="{ target: '_blank' }"
                  :source="slot.task.relatable.description"
                ></vue-markdown>
              </td>
            </tr>
            <tr v-if="manager">
              <td v-if="manager.user">
                {{ $t("Manager") }}:
              </td>
              <td v-if="manager.user">
                <strong
                  >{{ manager.user.first_name }}
                  {{ manager.user.last_name }}</strong
                >
              </td>
            </tr>
            <tr v-if="manager && manager.phone_company">
              <td></td>
              <td>
                <a :href="'tel:' + manager.phone_company">{{
                  manager.phone_company
                }}</a>
              </td>
            </tr>
            <tr v-if="manager && manager.user && manager.user.email">
              <td></td>
              <td>
                <a :href="'mailto:' + manager.user.email">{{
                  manager.user.email
                }}</a>
              </td>
            </tr>
            <tr v-if="slot.files && slot.files.length">
              <td>{{ $t("Bestanden") }}:</td>
              <td>
                <div v-for="file in slot.files" :key="file.uuid" class="mb-2">
                  <button class="btn btn-outline-secondary btn-sm w-100" @click='downloadFile(file.uuid, file.filename)'>
                      <img src="img/icon-download.png" alt="" height="15px">
                      {{ file.filename  }}
                  </button>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </article>
      <article class="accordion-item">
        <button class="accordion-item__header">
          <div class="img">
            <img src="img/icon-clock.png" alt="" />
          </div>
          <div class="title">{{ $t("Timeregistration") }}</div>
          <span v-if="needsTracking" class="badge">!</span>
        </button>
        <div class="timeregistration">
          <div class="alert alert-warning" v-if="!canTrackTime">
            {{ $t("You do not have any timeregistrations for selected date!") }}
          </div>
        </div>
        <span
          class="timeregistration-summary"
          v-if="calculate_total() > 0 || slot.badge_times"
        >
          {{ $t("Total") }}:
          <strong>{{
            $moment
              .duration(calculate_total(), "milliseconds")
              .format("HHumm", { trim: false })
          }}</strong>
          <div v-if="badge_times && (badge_times.in || badge_times.out)">
            <hr />
            <span v-if="badge_times.in"
              >{{ $t("Checkin") }}:
              <strong>{{
                $moment(badge_times.in.time, "HH:mm:ss").format("HH:mm")
              }}</strong></span
            >
            |
            <span v-if="badge_times.out"
              >{{ $t("Checkout") }}:
              <strong>{{
                $moment(badge_times.out.time, "HH:mm:ss").format("HH:mm")
              }}</strong></span
            >
          </div>
        </span>
        <div v-for="(tracking, index) in slot.timetracking" :key="index">
          <TimeRegistration
            ref="cmpTimetracking"
            :badgetimes="slot.badge_times"
            :trackingAccuracy="settings.accuracy_of_timetracking"
            v-on:update="trackingUpdated()"
            v-on:delete="removeTracking(index)"
            v-model="slot.timetracking[index]"
            :timeslot="slot"
            :equipment="equipment"
            :collapsed="slot.timetracking.length > 1"
            :settings="settings"
            @validated="timetrackingsValidated"
          ></TimeRegistration>
        </div>
        <div class="timeregistration" v-if="editable && canTrackTime">
          <button
            class="btn-add btn-add--lg"
            v-on:click="addTracking"
            v-if="editable"
          ></button>
        </div>
      </article>
    </section>
    <button
      class="btn-save"
      v-on:click="saveTracking"
      v-if="editable && valid() && canTrackTime"
    >
      <img :src="saveButton.icon" /> <span>{{ saveButton.text }}</span>
    </button>
    <div class="alert alert-warning btn-save" v-if="!valid() || !canTrackTime">
      {{ $t("Some values are invalid or incomplete") }}
    </div>
  </main>
</template>

<script>
import VueMarkdown from "vue-markdown";
import TimeRegistration from "./../components/TimeRegistration.vue";

export default {
  name: "task",
  components: {
    VueMarkdown,
    TimeRegistration,
  },
  methods: {
    downloadFile: function(fileId, fileName){
        this.$api.downloadFile(this.$config.get("account").id, fileId, fileName);
    },
    trackingUpdated: function () {
      this.checkOverlap();
    },
    removeTracking: function (index) {
      var tracking = this.slot.timetracking[index];
      if (tracking.saved != false) {
        this.trackings_to_remove.push(tracking.id);
      }
      this.slot.timetracking.splice(index, 1);
    },
    addTracking: function (saved) {
      if (typeof saved == "undefined") {
        saved = true;
      }
      this.slot.timetracking.push({
        start: this.slot.time_start,
        end: this.showEndtime == 1 ? this.slot.time_end : "00:00",
        pause: "00:00",
        remarks: "",
        slot_id: this.slot.id,
        task_id: this.slot.task_id,
        task_is_finished: false,
        date: this.$route.query.date,
        saved: saved,
      });

      this.checkBadging();
    },
    saveTracking: function () {
      if (this.loading) {
        return false;
      }

      this.loading = true;

      let sendData = {
        slot_id: this.slot.id,
        timetrackings: JSON.parse(JSON.stringify(this.slot.timetracking)),
        remove: this.trackings_to_remove,
      };

      var self = this;
      this.$api
        .postTracking(this.$config.get("account").id, sendData)
        .then(function () {
          self.$router.push({
            name: "tasksaved",
            query: { date: self.$route.query.date },
          });
        })
        .catch(function () {
          self.$router.push({
            name: "tasksaved",
            query: { date: self.$route.query.date, offline: true },
          });
        });
    },
    timetrackingsValidated(valid) {
      this.timetrackingsValid = valid;
      //this.$forceUpdate();
    },
    valid: function () {
      if (this.slot.timetracking) {
        for (let i = 0; i < this.slot.timetracking.length; i++) {
          const element = this.slot.timetracking[i];
          if (!element.valid) {
            return false;
          }
        }
      }

      if (!this.timetrackingsValid) {
        return false;
      }

      return true;
    },
    checkOverlap: function () {
      var format = "hh:mm:ss";
      var timetrackings = [];

      for (let k = 0; k < this.dayslots.length; k++) {
        const slot = this.dayslots[k];
        if (slot.id != this.slot.id) {
          for (let b = 0; b < slot.timetracking.length; b++) {
            const element = slot.timetracking[b];
            element.index = null;
            timetrackings.push(element);
          }
        }
      }

      for (let i = 0; i < this.slot.timetracking.length; i++) {
        const element = this.slot.timetracking[i];
        element.index = i;
        timetrackings.push(element);
      }

      for (let i = 0; i < this.slot.timetracking.length; i++) {
        this.slot.timetracking[i].overlap = false;
        const timetracking = this.slot.timetracking[i];
        var timetracking_starttime = this.$moment(timetracking.start, format);
        var timetracking_endtime = this.$moment(timetracking.end, format);
        for (let j = 0; j < timetrackings.length; j++) {
          // Don't compare same timetrackings
          const element = timetrackings[j];
          if (i != element.index) {
            var element_starttime = this.$moment(element.start, format);
            var element_endtime = this.$moment(element.end, format);

            if (timetracking_starttime >= element_starttime) {
              if (element_endtime > timetracking_starttime) {
                this.slot.timetracking[i].overlap = true;
              }
            } else if (timetracking_endtime > element_starttime) {
              this.slot.timetracking[i].overlap = true;
            }
          }
        }
      }
      this.$forceUpdate();
    },
    calculate_total: function () {
      var total_tracking = 0;
      for (let i = 0; i < this.slot.timetracking.length; i++) {
        const tracking = this.slot.timetracking[i];
        let end = this.$moment(tracking.end, "HH:mm:ss");
        let start = this.$moment(tracking.start, "HH:mm:ss");
        var duration = this.$moment
          .duration(end.diff(start))
          .subtract(tracking.pause);
        total_tracking += duration;
      }

      return total_tracking;
    },
    init: function (data) {
      var self = this;
      if (typeof data === "undefined") {
        this.$router.push({ name: "home" });
        return;
      }
      for (let i = 0; i < data.timetracking.length; i++) {
        if (data.timetracking[i].date != this.$route.query.date) {
          data.timetracking.splice(i, 1);
        }
      }
      this.slot = data;
      if (this.slot.task.relatable) {
        if (this.slot.task.relatable.manager) {
          this.manager = this.slot.task.relatable.manager;
        }
      }
      if (this.slot.timetracking.length == 0) {
        this.addTracking(false);
      }

      this.slot.timetracking.sort(function (a, b) {
        return self.$moment(a.start, "HH:mm:ss") >
          self.$moment(b.start, "HH:mm:ss")
          ? 1
          : -1;
      });

      this.$api
        .getBadgingTimes(
          this.$config.get("account").id,
          this.date.format("YYYY-MM-DD")
        )
        .then((response) => {
          self.badgings = response;
          this.checkBadging();
        });

      // Getting al slots from day to check overlap
      this.$api
        .getSlots(
          this.$config.get("account").id,
          this.slot.date,
          this.slot.date
        )
        .then((slots) => {
          self.dayslots = slots;
          this.checkOverlap();
        });
    },
    checkBadging: function () {
      this.badge_times = {};

      if (this.badgings.length > 0) {
        this.badge_times.in = this.badgings[0];
        this.badge_times.out =
          this.badgings[this.badgings.length - 1].next_badge_time;
      }

      for (let i = 0; i < this.slot.timetracking.length; i++) {
        this.slot.timetracking[i].badge_in = this.badge_times.in;
        this.slot.timetracking[i].badge_out = this.badge_times.out;
      }
    },
  },
  mounted: function () {
    var self = this;

    this.$config.getUsername().then((username) => {
      this.userName = username;
    });

    if (!this.$route.query.slot || !this.$route.query.date) {
      this.$router.push({ name: "home" });
      return;
    }

    this.date = this.$moment(this.$route.query.date);

    this.$config.checkOnline(function (online) {
      self.online = online;
    });

    this.$database.readAllData("equipment").then((data) => {
      self.equipment = data;
    });

    this.$pwa.getSettings().then((data) => {
      self.settings = data;
      self.$api
        .getSlot(
          self.$config.get("account").id,
          parseInt(self.$route.query.slot)
        )
        .then((slot) => {
          self.init(slot);
        })
        .catch(function () {
          self.$database
            .getDataById("slots", parseInt(self.$route.query.slot))
            .then((slot) => {
              self.init(slot);
            });
        });
    });
  },
  data: function () {
    return {
      slot: {
        date: "",
        task: {},
        timetracking: [],
      },
      dayslots: [],
      equipment: [],
      settings: {
        accuracy_of_timetracking: 1,
        show_end_time_of_task: 0,
        can_only_track_within_badge_times: 0,
        can_see_project_description: 1,
        can_see_task_description: 1,
      },
      badgings: [],
      online: true,
      loading: false,
      manager: null,
      userName: "",
      validTime: true,
      date: this.$moment(),
      trackings_to_remove: [],
      badge_times: null,
      updateButton: 0,
      timetrackingsValid: true,
    };
  },
  computed: {
    not_today: function () {
      return (
        this.date.format("YYYY-MM-DD") != this.$moment().format("YYYY-MM-DD")
      );
    },
    showEndtime: function () {
      if (this.settings.show_end_time_of_task) {
        return this.settings.show_end_time_of_task == 1;
      } else {
        return false;
      }
    },
    showProjectDescription: function () {
      if (this.settings.can_see_project_description) {
        return this.settings.can_see_project_description == 1;
      } else {
        return true;
      }
    },
    showTaskDescription: function () {
      if (this.settings.can_see_task_description) {
        return this.settings.can_see_task_description == 1;
      } else {
        return true;
      }
    },
    saveButton: function () {
      if (this.loading) {
        return {
          icon: "img/loading.svg",
          text: this.$t("Saving..."),
        };
      } else {
        return { icon: "img/icon-check-white.png", text: this.$t("Save") };
      }
    },
    client: function () {
      let client = {
        name: null,
        phone: null,
        email: null,
      };
      if (this.slot.task.relatable_type == "contact") {
        if (this.slot.task.relatable) {
          client.name =
            this.slot.task.relatable.first_name +
            " " +
            this.slot.task.relatable.last_name;
          client.phone = this.slot.task.relatable.phone;
          client.email = this.slot.task.relatable.email;
        }
      } else if (this.slot.task.relatable_type == "company") {
        if (this.slot.task.relatable) {
          client.name = this.slot.task.relatable.company_name;
          client.phone = this.slot.task.relatable.phone;
          client.email = this.slot.task.relatable.email;
        }
      } else if (this.slot.task.relatable_type == "project") {
        if (this.slot.task.relatable) {
          if (this.slot.task.relatable.clientable_type == "contact") {
            if (this.slot.task.relatable.clientable) {
              client.name =
                this.slot.task.relatable.clientable.first_name +
                " " +
                this.slot.task.relatable.clientable.last_name;
              client.phone = this.slot.task.relatable.clientable.phone;
              client.email = this.slot.task.relatable.clientable.email;
            }
          } else if (this.slot.task.relatable.clientable_type == "company") {
            if (this.slot.task.relatable.clientable) {
              client.name = this.slot.task.relatable.clientable.company_name;
              client.phone = this.slot.task.relatable.clientable.phone;
              client.email = this.slot.task.relatable.clientable.email;
            }
          }
        }
      }
      return client;
    },
    needsTracking: function () {
      return (
        this.$moment(
          this.slot.date + " " + this.slot.time_end,
          "YYYY-MM-DD HH:mm:ss"
        ) < this.$moment() && this.slot.timetracking === null
      );
    },
    canTrackTime: function () {
      if (
        this.settings.can_only_track_within_badge_times > 0 &&
        this.badge_times &&
        typeof this.badge_times.in == "undefined"
      ) {
        return false;
      }

      return true;
    },
    editable: function () {
      if (!(this.slot.timetracking && this.slot.timetracking.accepted == 1)) {
        return true;
      }
      return false;
    },
  },
};
</script>
